import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";

import ArrowRight from "../../assets/icons/arrow-right.svg";
import RoomSize from "../room-size";

import styles from "./offering-list-item.module.scss";

const OfferingListItem = ({
  name,
  mobileName,
  subtitle,
  roomSize,
  image,
  href,
  linkTag = Link,
  linkProps = {},
  showArrow = true
}) => {
  const ref = useRef();
  const [inFocus, setInFocus] = useState(false);

  const LinkTag = linkTag;

  useEffect(() => {
    const handleFocusScroll = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        // check if element is in middle of screen
        if (
          rect.top < window.innerHeight / 2 &&
          rect.bottom > window.innerHeight / 2
        ) {
          setInFocus(true);
        } else if (
          rect.top > window.innerHeight / 2 ||
          rect.bottom < window.innerHeight / 2
        ) {
          setInFocus(false);
        }
      }
    };

    window.addEventListener("scroll", handleFocusScroll);

    return () => window.removeEventListener("scroll", handleFocusScroll);
  }, [ref]);

  return (
    <LinkTag {...linkProps} href={href} passHref>
      <a ref={ref}>
        <div
          className={clsx(styles.OfferingListItem, inFocus && styles.Focused)}
        >
          <div className={styles.Info}>
            <h2 className={styles.Name}>{name}</h2>
            <h2 className={styles.MobileName}>{mobileName}</h2>
            <div className={styles.Description}>{subtitle}</div>
            <div className={styles.RoomSize}>
              {roomSize && <RoomSize roomSize={roomSize} />}
            </div>
          </div>
          <div className={styles.ImageContainer}>
            <div className={styles.Image}>
              {image && (
                <Image src={image.url} alt={image.description} layout="fill" />
              )}
            </div>
            {showArrow && (
              <div className={styles.ArrowContainer}>
                <ArrowRight width="13" role="presentation" />
              </div>
            )}
          </div>
        </div>
      </a>
    </LinkTag>
  );
};

export default OfferingListItem;
