import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import useViewport, { Viewport } from "../../hooks/useViewport";

import CarouselButtons from "../carousel-buttons/carousel-buttons";

import styles from "./news-slider.module.scss";

const NewsSlide = ({ post }) => {
  return (
    <Link href={`/news/${post.slug}`} passHref>
      <div key={post.title} className={styles.Post}>
        <div className={styles.Image}>
          <Image
            src={post.featuredImage.url}
            alt={post.title}
            layout="fill"
            objectFit="cover"
          />
        </div>
        <div className={styles.Info}>
          <div className={styles.Title}>{post.title}</div>
          <div className={styles.Byline}>{post.byline}</div>
        </div>
      </div>
    </Link>
  );
};

const NewsSlider = ({ posts }) => {
  const device = useViewport();

  const [currentSlide, setCurrentSlide] = useState(0);

  if (device === Viewport.PHONE) {
    return (
      <div className={styles.MobileCarousel}>
        <Carousel
          showArrows={false}
          showStatus={false}
          infiniteLoop={false}
          showIndicators={false}
          autoPlay={false}
          selectedItem={currentSlide}
          swipeScrollTolerance={20}
          preventMovementUntilSwipeScrollTolerance
          onChange={(index) => setCurrentSlide(index)}
        >
          {posts.map((post) => (
            <NewsSlide key={post.title} post={post} />
          ))}
        </Carousel>

        <CarouselButtons
          slides={posts.map((post, index) => ({
            active: index === currentSlide
          }))}
          onChange={(index) => setCurrentSlide(index)}
        />
      </div>
    );
  }

  return (
    <div className={styles.Scroll}>
      <div className={styles.Posts}>
        {posts.map((post) => (
          <NewsSlide key={post.title} post={post} />
        ))}
      </div>
    </div>
  );
};

export default NewsSlider;
