import clsx from "clsx";

import styles from "./carousel-buttons.module.scss";

const CarouselButtons = ({ className, slides, onChange }) => {
  return (
    <div className={clsx(styles.CarouselButtons, className)}>
      {slides.map((slide, index) => (
        <button
          key={`button_${index}`}
          onClick={() => onChange(index)}
          className={clsx(styles.CarouselButton, slide.active && styles.Active)}
        ></button>
      ))}
    </div>
  );
};

export default CarouselButtons;
