import UserIcon from "../../assets/icons/user.svg";

import styles from "./room-size.module.scss";

const RoomSize = ({ roomSize }) => {
  return (
    <div className={styles.RoomSize}>
      <UserIcon
        width="12"
        height="12"
        className={styles.Icon}
        role="presentation"
      />
      <div className={styles.Number}>{roomSize}</div>
    </div>
  );
};

export default RoomSize;
