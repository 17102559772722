import { fetchContent } from "./contentful";

const meetOurClientsQuery = (slug) => `
query {
    meetOurClientsCollection(limit: 1, where: {slug: "${slug}"}) {
      items {
        title
        description
        articlesCollection {
          items {
            featuredImage {
              url
              title
              description
            }
            slug
            title
            category
          }
        }
        clientLogosCollection {
          items {
            url
            title
            description
          }
        }
      }
    }
  }
`;

export async function getMeetOurClients(slug = "meet-our-clients") {
  const data = await fetchContent(meetOurClientsQuery(slug));

  if (!data) return null;

  return data.data.meetOurClientsCollection.items[0];
}
