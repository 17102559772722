import clsx from "clsx";
import { useEffect, useState } from "react";

import { getMeetOurClients } from "../../lib/meet-our-clients";

import NewsSlider from "../news-slider";

import styles from "./meet-our-clients.module.scss";

const MeetOurClients = ({ showLogos = true, slug }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [posts, setPosts] = useState([]);
  const [logos, setLogos] = useState([]);

  const filteredPosts = posts.map((post) => ({
    ...post,
    byline: post.category
  }));

  useEffect(() => {
    getMeetOurClients(slug).then((data) => {
      setTitle(data.title);
      setDescription(data.description);
      setPosts(data.articlesCollection.items);
      setLogos(data.clientLogosCollection.items);
    });
  }, [slug]);

  return (
    <div className={clsx("container", styles.MeetOurClients)}>
      <h2>{title}</h2>

      <p>{description}</p>

      <NewsSlider posts={filteredPosts} />

      {showLogos && (
        <div className={styles.LogoList}>
          {logos.map((logo, index) => (
            <div key={`${index}_${logo.name}`} className={styles.LogoImage}>
              <img src={logo.url} alt={logo.description} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MeetOurClients;
