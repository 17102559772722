import Meta from "../meta";

const SEOMeta = ({
  pageTitle,
  pageDescription,
  canonicalUrl,
  featuredImage
}) => {
  return (
    <Meta
      title={pageTitle}
      description={pageDescription}
      url={canonicalUrl}
      image={featuredImage?.url}
    />
  );
};

export default SEOMeta;
