import Head from "next/head";

import config from "../../config";
import basepath from "../../utils/basepath";

export default function Meta({ title, url, description, image }) {
  const pageTitle = title ? title : "East Room";
  const pageDescription = description
    ? description
    : "Office Space and Shared Workspace Solutions";
  const pageImage = image ? image : basepath("/images/workspaces-etc.png");
  const canonicalUrl = url ? url : config.BASE_URL;

  return (
    <Head>
      {/* Primary meta tags */}
      <title>{pageTitle}</title>
      <meta name="title" content={pageTitle} />
      <meta name="description" content={pageDescription} />
      <link rel="canonical" href={canonicalUrl} />

      {/* Open Graph / Facebook */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:site_name" content="East Room" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:image" content={pageImage} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={canonicalUrl} />
      <meta property="twitter:title" content={pageTitle} />
      <meta property="twitter:description" content={pageDescription} />
      <meta property="twitter:image" content={pageImage} />

      <meta name="apple-itunes-app" content="app-id=1595060251" />
    </Head>
  );
}
